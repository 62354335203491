import { lang } from "Figured/Assets/Modules";

export default {
    methods: {
        menuIconClass(menuItem) {
            return "fa-solid left " + menuItem.icon
        },

        filteredMenuItems(menuItems) {
            var items = [];
            var index = 1;
            _.each(menuItems, (value, key) => {
                if (_.has(value, "name") && _.has(value, "url") && value["name"] && value["url"]) {
                    items.push({
                        "name": value["name"],
                        "url": value["url"],
                        "id": _.get(value, "id"),
                        "icon": _.get(value, "icon", ""),
                        "extraClasses": _.get(value, "extraClasses", ""),
                        "target": _.get(value, "target", ""),
                        "index": "1-"+ index,
                        "divider": _.get(value, "divider", false),
                    });
                    index += 1;
                }
            });
            return items;
        },

        isActive(item){
            return _.includes(item.extraClasses, "active");
        },

        getDefaultActive(items) {
            var index = 1;
            for (var item of items) {
                if (_.includes(item.extraClasses, "active")) {
                    return "1-"+index;
                }
                index += 1;
            }

            return "";
        },

        transMenuItem(item) {
            return lang.trans(item);
        }
    }
}
